import React from 'react';

import { colors } from '../../styles/variables';

import { SectionAppearanceProvider } from '../../components/sections/SectionContext';

import Seo from '../../components/seo';
import Navbar from '../../components/organisms/Navbar';
import {
    SectionHeroStart,
    SectionOrderedItems,
    SectionVideoContainer,
    SectionIconItems,
    SectionAccordion,
    SectionInlineTiles,
    SectionBigTiles,
} from '../../components/sections';

import {
    PAYU_NAVBAR_CONFIG,
    PAYU_HERO_CONFIG,
    PAYU_ORDERED_ITEMS_CONFIG,
    PAYU_VIDEO_CONTAINER_CONFIG,
    PAYU_ICON_ITEMS_CONFIG,
    PAYU_ACCORDION_SECTION,
    PAYU_INLINE_TILES_CONFIG,
    PAYU_BIG_TILES_CONFIG,
} from '../../content_config_payu';
import CookieModal from '../../components/organisms/CookieModal';

const IndexPayU = () => (
    <div className="page-body sticky-navbar">
        <SectionAppearanceProvider>
            <Seo title="Start PayPo.pl" />

            <Navbar
                config={ PAYU_NAVBAR_CONFIG }
                sticky
            />

            <SectionHeroStart
                config={ PAYU_HERO_CONFIG }
            />

            <SectionOrderedItems
                config={ PAYU_ORDERED_ITEMS_CONFIG }
            />

            <SectionVideoContainer
                config={ PAYU_VIDEO_CONTAINER_CONFIG }
                backgroundBottom={ colors.grayCultured }
            />

            <SectionIconItems
                config={ PAYU_ICON_ITEMS_CONFIG }
                background={ colors.grayCultured }
            />

            <SectionAccordion
                config={ PAYU_ACCORDION_SECTION }
            />

            <SectionInlineTiles
                config={ PAYU_INLINE_TILES_CONFIG }
            />

            <SectionBigTiles
                config={ PAYU_BIG_TILES_CONFIG }
            />

            <CookieModal />
        </SectionAppearanceProvider>
    </div>
);

export default IndexPayU;
