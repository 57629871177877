import React from 'react';
import {
    ItemBag,
    ItemBlocks,
    ItemBox,
    ItemBoxes,
    ItemCalendar,
    ItemGift,
    ItemMoney,
    ItemStopwatch,
} from './assets/images';

import { colors } from './styles/variables';

import {
    heroRef,
    howItWorksRef,
    benefitsRef,
    faqRef,
    opinionsRef,
    contactRef,
} from './utils/sectionRefs';

import { LogoPayPoPayU } from './assets/images/index';

export const PAYU_NAVBAR_CONFIG = {
    customLogo: {
        logo: <LogoPayPoPayU />,
        width: "299px"
    },
    navigationLinks: [
        {
            id: 1,
            name: "Jak to działa?",
            relatedSection: howItWorksRef.navigationRef,
            action: () => howItWorksRef.moveToSection(),

        },
        {
            id: 2,
            name: "Korzyści",
            relatedSection: benefitsRef.navigationRef,
            action: () => benefitsRef.moveToSection(),
        },
        {
            id: 3,
            name: "FAQ",
            relatedSection: faqRef.navigationRef,
            action: () => faqRef.moveToSection(),
        },
        {
            id: 4,
            name: "Opinie",
            relatedSection: opinionsRef.navigationRef,
            action: () => opinionsRef.moveToSection(),
        },
        {
            id: 5,
            name: "Kontakt",
            relatedSection: contactRef.navigationRef,
            action: () => contactRef.moveToSection(),
        },
    ],
};

export const PAYU_HERO_CONFIG = {
    title: "Kup teraz, zapłać za 30 dni",
    id: "section-hero",
    forwardedRef: heroRef.forwardedRef,
    navigationRef: heroRef.navigationRef,
    subtitle: "",
    content: [
        {
            text: "Zamawiasz jak zwykle w sklepach obsługiwanych przez PayPo. Odbierasz zakupy, sprawdzasz, czy Ci pasują, a płacisz nawet 30 dni po złożeniu zamówienia. Bez żadnych kosztów.",
        },
    ],
    heroGraphic: "start",
};

export const PAYU_ORDERED_ITEMS_CONFIG = {
    title: "Jak to działa?",
    id: "section-how-it-works",
    forwardedRef: howItWorksRef.forwardedRef,
    navigationRef: howItWorksRef.navigationRef,
    itemList: [
        {
            id: 1,
            title: "Krok 1",
            text: "Robiąc zakupy w sklepie, wybierasz PayPo jako metodę płatności.  ",
        },
        {
            id: 2,
            title: "Krok 2",
            text: "Jeśli nie masz jeszcze konta w PayPo, podajesz swoje dane. Kolejne zakupy potwierdzasz jedynie kodem SMS lub jednym kliknięciem w aplikacji mobilnej.",
        },
        {
            id: 3,
            title: "Krok 3",
            text: "PayPo opłaca Twoje zakupy, a Ty otrzymujesz zamówienie.",
        },
        {
            id: 4,
            title: "Krok 4",
            text: "Sprawdzasz zakupy w domu, a na spłatę należności dla PayPo masz aż 30 dni. Pamiętaj, że płacisz tylko za te produkty, które zatrzymasz.",
        }
    ],
    squares: [
        {
            top: "11.5rem",
            left: "-17rem",
            size: "20rem",
            bgColor: colors.paypoGreen500,
            rotate: "45deg",
        }
    ],
};

export const PAYU_VIDEO_CONTAINER_CONFIG = {
    videoId: "U3Uc1lwXHR8",
    squares: [
        {
            top: "6rem",
            left: "7.2rem",
            size: "4.8rem",
            bgColor: colors.fuchsia500,
            rotate: "81.39deg",
        },
        {
            top: "33rem",
            right: "7.2rem",
            size: "4.8rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            top: "11rem",
            right: "-15.5rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const PAYU_ICON_ITEMS_CONFIG = {
    title: "Korzyści",
    id: "section-benefits",
    forwardedRef: benefitsRef.forwardedRef,
    navigationRef: benefitsRef.navigationRef,
    content: [
        {
            Icon: ItemCalendar,
            description: "Masz aż 30 dni na zapłatę - <b>bez dodatkowych kosztów</b>",
        },
        {
            Icon: ItemBlocks,
            description: "<b>Wygodne doświadczenia zakupowe</b> - o terminach płatności przypominamy Ci mailowo i SMSem",
        },
        {
            Icon: ItemBoxes,
            description: "<b>Sprawdzasz produkty w domu</b> - nieudane zakupy odsyłasz, płacisz tylko za te, które zatrzymasz",
        },
        {
            Icon: ItemGift,
            description: "<b>Możesz pozwolić sobie na więcej</b> - zaszaleć na wyprzedażach lub kupić prezent sobie czy bliskim, a płatność za zakupy rozciągnąć w czasie",
        },
        {
            Icon: ItemBag,
            description: "<b>Robisz pewne zakupy</b> - najpierw odbierasz i sprawdzasz zamówienie, a płacisz dopiero, gdy jesteś pewny zakupu",
        },
        {
            Icon: ItemBox,
            description: "<b>Nie tracisz wyjątkowych okazji</b> - kupujesz, kiedy się najbardziej opłaca, a płacisz wygodnie później",
        },
        {
            Icon: ItemMoney,
            description: "Z czasem możesz jeszcze więcej - <b>Twój limit na kolejne zakupy może wzrosnąć aż do 5000zł</b>",
        },
        {
            Icon: ItemStopwatch,
            description: "Błyskawiczne i bezpieczne płatności - kolejne transakcje potwierdzasz tylko kodem SMS lub <b>jednym kliknięciem w aplikacji</b>",
        },
    ],
    additionalText: [
        {
            text: 'Usługa jest dostępna dla nowych klientów PayPo. Informacja nie stanowi oferty. Szczegóły w Regulaminie usługi “Zapłać za 30 dni” dostępnym na <a href=\'https://www.paypo.pl\'>www.paypo.pl</a>. Klienci PayPo po spłaceniu jednej transakcji w całości, zaczynają korzystać z usług, które umożliwiają spłatę zamówienia w ciągu 30 dni lub w ratach. <a href=\'https://www.paypo.pl/#section-state-control-buttons\'>Sprawdź</a>.'
        },
    ],
    squares: [
        {
            bottom: "-14rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const PAYU_ACCORDION_SECTION = {
    title: "Najczęściej zadawane pytania",
    id: "section-faq",
    forwardedRef: faqRef.forwardedRef,
    navigationRef: faqRef.navigationRef,
    content: [
        {
            id: "1",
            title: "1. Czy muszę podawać dane swojej karty kredytowej/debetowej?",
            text: "Nie, do skorzystania z płatności PayPo nie wymagamy podania danych karty kredytowej/debetowej.",
            isExpanded: true,
        },
        {
            id: "2",
            title: "2. W jaki sposób płacę za zamówienie?",
            text: "Możesz zapłacić przelewem bankowym, przekazem pocztowym lub szybkimi płatnościami online - jak Ci wygodniej. Numer konta do wpłaty otrzymasz mailem wraz z potwierdzeniem złożenia zamówienia. Dla Twojej wygody udostępniamy panel Klienta - nie masz obowiązku logować się do niego, ale możesz. Tam masz bieżący podgląd wszystkich ważnych informacji.",
        },
        {
            id: "3",
            title: "3. Czy to jest bezpieczne?",
            text: "Tak, wszystkie płatności są realizowane w ramach szyfrowanego połączenia, a Ty nie podajesz żadnych danych dostępowych do swojego konta.",
        },
        {
            id: "4",
            title: "4. Czy to coś kosztuje?",
            text: "Nie. Korzystając z usługi “Zapłać za 30 dni”, w ciągu 30 dni spłacasz tylko tyle, ile wynosi wartość zamówienia - bez dodatkowych kosztów.",
        },
        {
            id: "5",
            title: "5. Do kiedy muszę spłacić transakcję?",
            text: "Masz aż 30 dni na spłatę swojego zamówienia. O zbliżającym się terminie spłaty przypomnimy Ci za pośrednictwem SMS i wiadomości e-mail.",
        },
        {
            id: "6",
            title: "6. Co się stanie, jeśli zwrócę zamówienie?",
            text: "Po prostu nie zapłacisz. Twoja transakcja zostanie anulowana w naszym systemie.",
        },
    ],
    squares: [
        {
            top: "54rem",
            left: "13rem",
            size: "6rem",
            bgColor: colors.fuchsia500,
            rotate: "78.54deg",
        },
        {
            top: "6rem",
            right: "10rem",
            size: "10rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            top: "-6rem",
            right: "-13rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};

export const PAYU_INLINE_TILES_CONFIG = {
    title: "Zobacz ponad 27000 pozytywnych opinii",
    id: "section-opinions",
    forwardedRef: opinionsRef.forwardedRef,
    navigationRef: opinionsRef.navigationRef,
    tileList: [
        {
            id: 1,
            score: '5.0',
            name: 'Monika',
            text: 'Jestem bardzo zadowolona z płatności z PayPo. W każdej chwili, kiedy czegoś potrzebuję, mogę zrobić szybko i sprawnie zakupy, a potem w dogodnym terminie dokonać spłaty.',
        },
        {
            id: 2,
            score: '5.0',
            name: 'Magdalena',
            text: 'Rewelacja - jak dla mnie najszybszy i najwspanialszy sposób zapłaty za przesyłki, które mogę spokojnie obejrzeć, przetestować, przymierzyć czy sprawdzić w domu. Bez nerwów, pośpiechu i zbędnego zamrażania gotówki. A przy tym tak pewnie i bezpiecznie. POLECAM',
        },
        {
            id: 3,
            score: '5.0',
            name: 'Ala',
            text: 'Nigdy się nie zawiodłam na usłudze PayPo. Czytelne i jasne zasady. Dobry system powiadomień. Korzystam i będę korzystać. ',
        },
    ],
    additionalText: [
        {
            text: 'Prezentowane opinie pochodzą z serwisu Opineo. Weryfikacja opinii realizowana jest zgodnie z Regulaminem dla użytkowników serwisu Opineo.pl',
        },
    ],
    cta: {
        type: "button",
        text: "Zobacz opinie na Opineo",
        link: "https://www.opineo.pl/opinie/paypo-pl",
    },
    squares: [
        {
            bottom: "18rem",
            left: "-8rem",
            size: "10rem",
            bgColor: colors.fuchsia500,
            rotate: "20.46deg",
        },
        {
            bottom: "-4.8rem",
            left: "-12rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
        {
            top: "24.8rem",
            right: "31rem",
            size: "14rem",
            bgColor: colors.paypoGreen500,
            rotate: "69.24deg",
        },
    ],
};

export const PAYU_BIG_TILES_CONFIG = {
    title: "Masz pytanie?",
    id: "section-contact",
    forwardedRef: contactRef.forwardedRef,
    navigationRef: contactRef.navigationRef,
    tileList: [
        {
            id: 1,
            bgColor: colors.mintCream,
            content: {
                title: "bok@paypo.pl",
                textContent: [
                    {
                        id: 1,
                        text: "Na większość pytań odpowiadamy w ciągu 48 godzin",
                    },
                ],
                cta: {
                    type: "button",
                    action: "email",
                    variant: "primary",
                    text: "Napisz do nas"
                }
            },
        },
        {
            id: 2,
            bgColor: colors.palePurple,
            content: {
                title: "+48 22 333 74 60",
                textContent: [
                    {
                        id: 1,
                        text: "Od poniedziałku do piątku od 8:00 do 18:00",
                    },
                    {
                        id: 2,
                        text: "Opłata za połączenie zgodna z taryfą operatora",
                    },
                ],
                cta: {
                    type: "textButton",
                    action: "phoneCall",
                    variant: "textButton",
                    text: "Zadzwoń do nas",
                },
            },
        },
    ],
    squares: [
        {
            top: "-15.2rem",
            left: "-12rem",
            size: "20rem",
            bgColor: colors.yellow500,
            rotate: "69.24deg",
        },
    ],
};
